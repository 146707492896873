import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Link, Route } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import { socialLogin, registration, closeAuthModal, openAuthModal  } from '../../stores/actions/auth-action'
import InstagramLogin from 'react-instagram-login';
//import { LinkedIn } from 'react-linkedin-login-oauth2';
import LinkedIn from '../../reuseables/linked-in';
import { toast } from 'react-toastify';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import ReCAPTCHA from "react-google-recaptcha";
import { GOOGLE_RECAPTCHA_SITE_KEY } from '../../constants/config';
import { RouteNames } from '../../includes/route-names';


const Registration = (props) => {
  const roles = useSelector(state => state.common.roles);
  const interest_master = useSelector(state => state.common.interest_master);
  const GOOGLE_CLIENT_ID = '80926907619-h8beld3ufkm88nm77ot37fr596s3gvlo.apps.googleusercontent.com'
  const FACEBOOK_APP_ID = '392514378432239'
  const INSTRAGRAM_ID = '255627602501304'
  //const LINKEDIN_CLIENT = '81lx5we2omq9xh'
  const LINKEDIN_CLIENT = '86fyutfylwp9gi';
  const dispatch = useDispatch();
  const emailRegex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
  const phonenoRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const [showDropDown, setShowDropDown] = useState(false)
  const [showRoleDropDown, setShowRoleDropDown] = useState(false)
  const dropDownRef = useRef();
  const dropDownRef1 = useRef();
  const [userInterestMaster, setUserInterestMaster] = useState([])
  const [form_error, setFormError] = useState({});
  const [registrationForm, setRegistrationForm] = useState({
    field_members_for: [],
    field_full_name: '',
    field_user_interest: [],
    field_expertise: '',
    field_user_interest_misc:'',
    mail: '',
    pass: '',
    is_agree:'',
    captcha:''
  });
  const [selectedLabel, setSelectdLabel] = useState([])
  const [selectedRoleLabel, setSelectdRoleLabel] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    const interests =  interest_master.filter(obj=>obj.field_taxonomy_type === "user_interest").map(obj=>({value:obj.tid, label: obj.name}))
    setUserInterestMaster(interests)
    setRegistrationForm({ ...registrationForm, ['field_user_interest']: interests.map(obi=>obi.value) });
    setSelectdLabel(interests.map(obi=>obi.label))
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    }
  }, [interest_master])

  const requireFiled =['field_members_for', 'field_full_name', 'mail','pass','captcha','is_agree'];


  const registerOpen = useSelector( state => state.auth.registrationOpen );
  
  const handleClickOutside = event => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShowDropDown(false)
    }
    if (dropDownRef1.current && !dropDownRef1.current.contains(event.target)) {
      setShowRoleDropDown(false)
    }
  };
  
  const handleClose = () =>{
    let errors = {};
		setFormError({ ...form_error, errors });
    dispatch(closeAuthModal())
  }


  const handleInput = (e) => {
    setRegistrationForm({ ...registrationForm, [e.target.name]: e.target.value })
    if(e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj=> obj[0] === e.target.name)){
      let errors = {};
     Object.entries(form_error.errors).filter(obj=> obj[0] !== e.target.name).map(obj=>errors[obj[0]] = obj[1]);
     setFormError({ ...form_error, errors });
    }

    if(e.target.value ==='' && requireFiled.indexOf(e.target.name)!== -1){
      let errors = {};
      errors[e.target.name] = `This is required field`;
      if(form_error.errors && Object.entries(form_error.errors).length){
        Object.entries(form_error.errors).map(obj=>errors[obj[0]] = obj[1]);
      }
      
      setFormError({ ...form_error, errors});
  }

  }

  const handleUserInterest =(value)=>{
    if(value && value.length){
      setRegistrationForm({ ...registrationForm, ['field_user_interest']: value.map(obj =>obj.value) });
    }else{
      setRegistrationForm({ ...registrationForm, ['field_user_interest']: [] });
    }
  }

  const handleCheckBox =(e)=>{
    const labelValue = userInterestMaster.find(obj=>obj.value == e.target.value)
    if(e.target.checked){
      setRegistrationForm({ ...registrationForm, ['field_user_interest']: registrationForm.field_user_interest.concat(e.target.value) });
      setSelectdLabel(selectedLabel.concat(labelValue.label))
    }else{
      setRegistrationForm({ ...registrationForm, ['field_user_interest']: registrationForm.field_user_interest.filter(obj => obj != e.target.value) });
      setSelectdLabel(selectedLabel.filter(obj=> obj != labelValue.label))
    }
  }

  const handleRoleCheckBox =(e)=>{
    const labelValue = roles.find(obj=>obj.id == e.target.value)
    if(e.target.checked){
      setRegistrationForm({ ...registrationForm, ['field_members_for']: registrationForm.field_members_for.concat(e.target.value) });
      setSelectdRoleLabel(selectedRoleLabel.concat(labelValue.label))
    }else{
      setRegistrationForm({ ...registrationForm, ['field_members_for']: registrationForm.field_members_for.filter(obj => obj != e.target.value) });
      setSelectdRoleLabel(selectedRoleLabel.filter(obj=> obj != labelValue.label))
    }
  }

  const clearForm = () => {
    setRegistrationForm({
      field_members_for: [],
      field_full_name: '',
      field_user_interest:[],
      field_expertise: '',
      field_user_interest_misc:'',
      mail: '',
      pass: '',
      is_agree:'',
      captcha:''
    })
    setSelectdRoleLabel([])
    setSelectdLabel([])
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = {};
    setFormError({ ...form_error, errors });
    let isValid = true;
    let formArr = Object.keys(registrationForm).map(obj => ({ key: String(obj), value: registrationForm[obj] }));
    if (formArr && formArr.length) {
      formArr.forEach(obj => {
        if (obj.value === '' && requireFiled.indexOf(obj.key)!== -1 ) {
          if(obj.key =='captcha'){
            errors[obj.key] = `Please select captch`;
            isValid = false;
          }else if(obj.key =='is_agree'){
            errors[obj.key] = `Please check agree`;
            isValid = false;
          }else{
            errors[obj.key] = `This is required field`;
            setFormError({ ...form_error, errors });
            isValid = false;            
          }

        }
        if (obj.key === 'mail' && obj.value !== '' && !emailRegex.test(obj.value)) {
          errors[obj.key] = `Please enter valid email`;
          setFormError({ ...form_error, errors });
          isValid = false;
        }
      });

    }
    if (isValid) {
       let formdata = {};
       formArr.forEach(field=>{
         if(field.key !== 'captcha' && field.key !== 'is_agree'){
           formdata[field.key] = field.value;
         }
         
       })

      setIsLoading(true)
      setFormError({})
       await dispatch(registration(formdata)).then(resp => {     
        setIsLoading(false)
           if(!resp.error){
            clearForm()
            handleClose();
            props.registrationSuccess()
            /* toast.success('Successfully register',
              {
                  position: toast.POSITION.TOP_RIGHT
              }); */
           }else{
            toast.error(resp.msg,
            {
                position: toast.POSITION.TOP_RIGHT
            });
           }
           
       });
    }
    
  }

  const responseFacebook = (response) => {
    if (response && response.id) {
      setIsLoading(true)
      const loginData = {
        id: response.id,
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
        social_type: 'facebook',
      }
      //setLoaded(true)           
      dispatch(socialLogin(loginData)).then(res => {
        setIsLoading(false)
        setUserDataToStore(res, 'social')
      })
    }
  }

  const responseGoogle = (response) => {
    if (response && response.profileObj) {
      setIsLoading(true)
      let user = response.profileObj
      const loginData = {
        id: user.googleId,
        name: user.name,
        email: user.email,
        photoUrl: user.imageUrl,
        social_type: 'google'
      }
      dispatch(socialLogin(loginData)).then(res => {
        setIsLoading(false)
        setUserDataToStore(res, 'social')
      });
    }
  }


	const setUserDataToStore =(resp, userType)=>{
		let userData = resp.data;
		userData.user_type = userType
		localStorage.setItem('user', JSON.stringify(userData))
    localStorage.setItem('csrf_token', JSON.stringify(resp.csrf_token))
    var formArr =[];
		Object.entries(userData.form).forEach(val=>{
			if(val[0] == 'bike_destination_form' && val[1] === true){
				formArr.push({name:'destination', order:6, icon:'destinationico.png'})
			}else if(val[0] == 'bike_expert_form' && val[1] === true){
				formArr.push({name:'enthusiasts', order:4, icon:'expertico.png'})
			}else if(val[0] == 'buy_form' && val[1] === true){
				formArr.push({name:'product', order:1, icon:'productico.png'})
			}else if(val[0] == 'event_form' && val[1] === true){
				formArr.push({name:'event', order:5, icon:'eventico.png'})
			}else if(val[0] == 'smart_solutions_form' && val[1] === true){
				formArr.push({name:'solutions', order:3, icon:'smartico.png'})
			}
    })
    formArr.push({ name: 'services', order: 2, icon: 'destinationico.png' })
		localStorage.setItem('user_forms', JSON.stringify(formArr.sort((a,b)=>(a.order - b.order) )))
    handleClose();
    toast.success('Welcome to carryon.bike platform. Enjoy the ride through our platform 🙂',
      {
          position: toast.POSITION.TOP_RIGHT
      });
    
  }

  
  const onChangeCaptcha =(value)=> {
    setRegistrationForm({ ...registrationForm, ['captcha']: value })
   if(value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj=> obj[0] === 'captcha')){
      let errors = {};
     Object.entries(form_error.errors).filter(obj=> obj[0] !== 'captcha').map(obj=>errors[obj[0]] = obj[1]);
     setFormError({ ...form_error, errors });
    }
    console.log("Captcha value:", value);
}

const handleIsAgree =(e)=>{
  if(e.target.checked){
     setRegistrationForm({ ...registrationForm, ['is_agree']: 1 })
     if(e.target.checked && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj=> obj[0] === 'is_agree')){
      let errors = {};
     Object.entries(form_error.errors).filter(obj=> obj[0] !== 'is_agree').map(obj=>errors[obj[0]] = obj[1]);
     setFormError({ ...form_error, errors });
    }
  }else{
    setRegistrationForm({ ...registrationForm, ['is_agree']: '' })
  }
}



  return (
    <Modal className="cmnmodal logmodal regmodal" size="lg" centered show={registerOpen} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="logholder">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="img">
          <img width="419" height="807" src={require('../../assets/images/regimg.jpg')} alt="" />
        </div>
        <div className="logholder">
          <div>
            <Link className="logo" to="#">
              <img src={require('../../assets/images/logo.png')} alt="" />
            </Link>
            <h3>Create your forever free <span className="carry">CarryOn<span>.bike</span></span> account</h3>
            <p>No credit card required during Sign Up for Basic Plan. No surprises. Just results.</p>
            <ul className="logsocial">
              <li>
                <FacebookLogin
                  appId={FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                  fields="name,email,picture"
                  callback={responseFacebook}
                  render={renderProps => (
                    <Link onClick={renderProps.onClick}><img src={require("../../assets/images/fb.png")} alt="" /></Link>
                  )}
                />
              </li>
              <li>
                <GoogleLogin
                  clientId={GOOGLE_CLIENT_ID}
                  render={renderProps => (
                    <Link onClick={renderProps.onClick} disabled={renderProps.disabled}><img src={require('../../assets/images/ggl.png')} alt="" /></Link>
                  )}
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </li>
              <li>
                <LinkedIn>
                  <img src={require('../../assets/images/linkdin.png')} alt="" />
                </LinkedIn>
              </li>

            </ul>
            <h4>
              <span>Or sign up with email</span>
            </h4>
            <span data-tip='You can always edit these details after registration' style={{float:'right'}} ><img src={require('../../assets/images/help.png')} alt="" style={{position:'relative', top:-10, width:20}}/></span>
            <ReactTooltip place='left'/>
            <div className="formdiv">
              <form onSubmit={handleSubmit}>
              <div class="form-group multiselect" ref={dropDownRef1}>
                        <input type="text" autoComplete='off' name="" placeholder="Join our Platform as" class=""  onClick={()=> setShowRoleDropDown(prevState=> !prevState)} id="msinp" value={selectedRoleLabel.length >2 ?selectedRoleLabel.slice(0,2).toString() + '...':selectedRoleLabel.toString()}/>
                        <ul class="dropdown" style={{display: showRoleDropDown ? 'block':'none' }}>
                          {
                            roles.length && roles.map((data, index)=>(
                              <li key={index}>
                                <input type="checkbox" name={data.label} checked={registrationForm.field_members_for.indexOf(data.id)!= -1?true:false } value={data.id} onChange={handleRoleCheckBox}/>
                                <label>{data.label}</label>
                              </li>
                            ))
                          }
                          
                          
                        </ul> 
                      </div>
                {/* <div className="form-group">
                  <select className="mandatory" name='field_members_for' onChange={handleInput}>
                    <option value=''>Join our Platform as</option>
                    {
                      roles.map((role, index)=>(
                      <option value={role.id} key={index}>{role.label}</option>
                      ))
                    }
                  </select>
                  {
                    form_error['errors'] && form_error['errors']['field_members_for'] && (
                      <span className='form_error'>{form_error['errors']['field_members_for']}</span>
                    )

                  }
                </div> */}
                
                <div className="form-group">
                  <input className="mandatory" type="text" name="field_full_name" placeholder="Full Name" onChange={handleInput} value={registrationForm.field_full_name}/>
                  {
                    form_error['errors'] && form_error['errors']['field_full_name'] && (
                      <span className='form_error'>{form_error['errors']['field_full_name']}</span>
                    )

                  }
                </div>
                  
                <div className="form-inline">
                <div class="form-group multiselect" ref={dropDownRef}>
                        <input type="text" name="" placeholder="I am interested in" class=""  onClick={()=> setShowDropDown(prevState=> !prevState)} id="msinp" value={selectedLabel.length >2 ?selectedLabel.slice(0,2).toString() + '...':selectedLabel.toString()}/>
                        <ul class="dropdown" style={{display: showDropDown ? 'block':'none' }}>
                          <li>I am interested in</li>
                          {
                            userInterestMaster.length && userInterestMaster.map((data, index)=>(
                              <li key={index}>
                                <input type="checkbox" name={data.label} checked={registrationForm.field_user_interest.indexOf(data.value)!= -1?true:false } value={data.value} onChange={handleCheckBox}/>
                                <label>{data.label}</label>
                              </li>
                            ))
                          }
                          
                          
                        </ul> 
                      </div>
									{/* <label>Interest Area</label> */}
                                    {/* <Select 
                                        isMulti
                                        options={userInterestMaster}
                                        name="userInterest"   
                                        placeholder="Interest Area"                 
                                        classNamePrefix="select" 
                                        onChange={handleUserInterest}
                                    /> */}



                  <div className="form-group">
                    <select className="" name='field_expertise' onChange={handleInput}>
                    <option value=''>Select Expertise</option>
                      {
                         interest_master.length && interest_master.filter(obj=>obj.field_taxonomy_type == "user_expertise").map((expert, index)=>(
                         <option value={expert.tid} key={expert.tid}>{expert.name}</option>
                         ))
                      }
                    </select>
                    {
                      form_error['errors'] && form_error['errors']['field_expertise'] && (
                        <span className='form_error'>{form_error['errors']['field_expertise']}</span>
                      )

                    }
                  </div>
                </div>
                <div className="form-group">
                  <input type="email" className="mandatory" name="mail" placeholder="Email" onChange={handleInput} value={registrationForm.mail}/>
                  {
                    form_error['errors'] && form_error['errors']['mail'] && (
                      <span className='form_error'>{form_error['errors']['mail']}</span>
                    )

                  }
                </div>
                <div className="form-group">
                  <input className="mandatory" type="password" name="pass" placeholder="Password" onChange={handleInput} value={registrationForm.pass}/>
                  {
                    form_error['errors'] && form_error['errors']['pass'] && (
                      <span className='form_error'>{form_error['errors']['pass']}</span>
                    )

                  }
                </div>
                <div className="form-group">
                <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={onChangeCaptcha}
                />
                {
                    form_error['errors'] && form_error['errors']['captcha'] && (
                      <span className='form_error' style={{top:5}}>{form_error['errors']['captcha']}</span>
                    )

                  }
                </div><br/>
                  <div className="form-group">
                    <label className="agree-lbl">
                     <input type="checkbox" value="" name="is_agree" onChange={handleIsAgree}/>
                     <span>Agree to <Link to={RouteNames.termsAndCondition} target="_blank">Terms & Conditions</Link></span>
                     
                     </label>
                      {
                    form_error['errors'] && form_error['errors']['is_agree'] && (
                      <span className='form_error' >{form_error['errors']['is_agree']}</span>
                    )

                  }
                  </div>

                <div className="form-group">
                  <input type="submit" name="" value="register" />
                </div>
              </form>
            </div>
            <Link className="forgot" to="#" onClick={()=>dispatch(openAuthModal('login'))}>Already have an account?</Link>
          </div>
        </div>
      </Modal.Body>
      <div className="loaddiv loaddiv1" style={{display: isLoading ? 'block':'none'}}>
          <span className="loader"></span>
      </div>
    </Modal>
  )
}

export default Registration
